<script setup lang="ts">
import { useMetaMaskStore } from '~/stores/meta-mask.store';
import { truncateMiddle } from '~/utilities/helpers/truncate-middle';
import { useLeaderboardStore } from '~/stores/leaderboard.store';
import useUtmParams from '~/composables/use-utm-params';

const leaderboardStore = useLeaderboardStore();
const metaMaskStore = useMetaMaskStore();

const isOpen = ref(false);

function toggleMenu() {
  isOpen.value = !isOpen.value;
}

if (leaderboardStore.leaderboardInfo === null) {
  leaderboardStore.getLeaderboardInfo();
}

const openPopup = ref(false);

//switch battons on mobile
const { utm_source, utm_medium, utm_campaign } = useUtmParams();

const googlePlayUrl = computed(() => {
  return `http://play.google.com/store/apps/details?id=io.newtify.app&referrer=utm_source%3D${utm_source}%26utm_medium%3D${utm_medium}%26utm_campaign%3D${utm_campaign}`;
});
</script>

<template>
  <div :class="['header-wrapper', { open: isOpen }]">
    <header class="header">
      <div class="header-menu-wrapper">
        <nuxt-link class="logo" to="/" @click="() => (isOpen = false)">
          <base-logo-header />
        </nuxt-link>
        <div class="header-menu-box">
          <div
            class="connect-mobile-wrapper"
            @click="metaMaskStore.toogleMetaMaskPopup"
          >
            <base-icon name="base/meta_mask" size="24px" filled />
          </div>

          <nuxt-link
            v-if="$device.isIos"
            href="https://apps.apple.com/us/app/newtify-io/id6482849312"
            class="install-app-button-mobile"
            target="_blank"
          >
            <base-icon name="base/apple" size="24px" filled />
          </nuxt-link>
          <nuxt-link
            v-else
            :href="googlePlayUrl"
            class="install-app-button-mobile"
            target="_blank"
          >
            <base-icon name="base/google" size="24px" filled />
          </nuxt-link>

          <div class="header-menu-button" @click="toggleMenu">
            <Transition name="menu-icon" mode="out-in">
              <base-icon
                v-if="!isOpen"
                name="base/menu_v2"
                size="40px"
                filled
              />
              <base-icon
                v-else
                name="base/close_x2"
                size="40px"
                filled
              />
            </Transition>
          </div>
        </div>
      </div>

      <div :class="['links-wrapper', { open: isOpen }]">
        <nuxt-link
          v-if="leaderboardStore.leaderboardInfo?.enableLeaderboard ?? true"
          to="/leaderboard"
          class="link hover-scale-light font--b2"
          @click="() => (isOpen = false)"
          >Leaderboard</nuxt-link
        >
        <nuxt-link
          to="/events"
          class="link hover-scale-light font--b2"
          @click="() => (isOpen = false)"
          >Events</nuxt-link
        >
        <nuxt-link
          to="/#withdraw"
          class="link hover-scale-light font--b2"
          @click="() => (isOpen = false)"
          >Withdraw</nuxt-link
        >
        <a
          class="link link-contact-us hover-scale-light font--b2"
          href="/#footer"
          @click="() => (isOpen = false)"
          >Contact us</a
        >
        <div class="account-wrapper">
          <button
            v-if="!metaMaskStore.metaMaskUserWallet"
            class="connect-button hover-scale-light font--b2"
            @click="metaMaskStore.toogleMetaMaskPopup"
          >
            Connect
            <base-icon name="base/meta_mask" size="24px" filled />
          </button>
          <button
            v-else
            class="connect-button account-button hover-scale-light"
            @click="metaMaskStore.toogleMetaMaskPopup"
          >
            <base-icon name="base/meta_mask" size="24px" filled />
            {{ truncateMiddle(metaMaskStore.metaMaskUserWallet, 5) }}
          </button>
          <button
            class="install-app-button hover-scale-light font--b2"
            @click="openPopup = true"
          >
            Install app
            <div class="install-app-button-icons-wrapper">
              <base-icon name="base/apple" size="24px" filled />
              <base-icon name="base/google" size="24px" filled />
            </div>
          </button>
        </div>
        <div class="social-links-wrapper">
          <nuxt-link
            to="https://t.me/newtify"
            class="social-link hover-scale-light"
            target="_blank"
          >
            <base-icon name="base/social_telegram" size="34px" filled />
          </nuxt-link>

          <nuxt-link
            to="https://discord.gg/WYWT9rKh6z"
            class="social-link social-link-discord hover-scale-light"
            target="_blank"
          >
            <base-icon name="base/social_discord" size="34px" filled />
          </nuxt-link>

          <nuxt-link
            to="https://x.com/Newtifyapp"
            class="social-link hover-scale-light"
            target="_blank"
          >
            <base-icon name="base/social_x" size="34px" filled />
          </nuxt-link>

          <nuxt-link
            to="https://www.youtube.com/@newtifyio"
            class="social-link social-link-discord hover-scale-light"
            target="_blank"
          >
            <base-icon name="base/social_youtube" size="34px" filled />
          </nuxt-link>
        </div>
      </div>
    </header>
  </div>
  <app-header-meta-mask-popup class="meta-mask-connect-popup" />
  <base-drawer
    v-model="metaMaskStore.openMetaMaskPopup"
    class="meta-mask-connect-drawer"
    ><app-header-meta-mask-connect-drawer
  /></base-drawer>
  <base-popup v-model="openPopup">
    <popup-get-app @close="openPopup = false" />
  </base-popup>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  max-width: 1440px;
  padding: 0 120px;
  margin: 0 auto;
}

.header-wrapper {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(248, 248, 248, 0.8);
  backdrop-filter: blur(2px);
  transition: all 0.3s ease;
}

.header-menu-box {
  display: none;
}

.links-wrapper {
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: flex-end;
  align-items: center;
}

.social-links-wrapper {
  display: none;
}

.link {
  text-decoration: none;
  color: var(--black);
}

.button {
  border-radius: 37px;
  border: 1px solid var(--black);
  padding: 12px 24px;
}

.account-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.connect-button {
  cursor: pointer;
  border-radius: 40px;
  background: var(--black);
  border: 1px solid var(--black);
  padding: 9px 23px;
  outline: none;
  margin: 0;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 8px;
}

.install-app-button {
  cursor: pointer;
  border-radius: 40px;
  background: var(--primary-orange);
  border: 1px solid var(--primary-orange);
  padding: 9px 23px;
  outline: none;
  margin: 0;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}

.install-app-button-icons-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}

.install-app-button-mobile {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  border-radius: 40px;
  background-color: var(--primary-orange);
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.connect-mobile-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: var(--black);
}

.meta-mask-connect-drawer {
  display: none;
}

@media (max-width: 1200px) {
  .header {
    height: 56px;
    padding: 0;
    flex-direction: column;
  }

  .header-wrapper.open {
    bottom: 0;
    width: 100%;
    height: 100dvh;
    backdrop-filter: unset;
    background: var(--bg);
    transition: all 0.3s ease;
  }

  .header-menu-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 24px;
    align-items: center;
    height: 100%;
  }

  .header-menu-box {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  .link {
    font-size: 16px;
  }

  .links-wrapper {
    display: none;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .links-wrapper.open {
    display: flex;
  }

  .social-links-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
  }

  .social-link {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--primary-orange);
  }

  .account-wrapper {
    display: none;
  }

  .connect-button {
    display: none;
  }

  .meta-mask-connect-popup {
    display: none;
  }

  .meta-mask-connect-drawer {
    display: unset;
  }
}

.menu-icon-enter-active,
.menu-icon-leave-active {
  transition: opacity 0.2s ease;
}

.menu-icon-enter-from,
.menu-icon-leave-to {
  opacity: 0;
}
</style>
