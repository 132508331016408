<script setup lang="ts">
import useMetaMask from '~/composables/meta-mask';
import { useMetaMaskStore } from '~/stores/meta-mask.store';
import { truncateMiddle } from '~/utilities/helpers/truncate-middle';

const metaMaskStore = useMetaMaskStore();

const { getUserWallet, disconnectMetaMask } = useMetaMask();

const state = defineModel<boolean>({ default: false });
</script>

<template>
  <div class="popup-wrapper">
    <div
      class="overlay"
      @click.stop="metaMaskStore.toogleMetaMaskPopup"
      v-if="metaMaskStore.openMetaMaskPopup"
    ></div>
    <transition name="popup">
      <div
        class="meta-mask-popup meta-mask-popup-connect"
        @click="getUserWallet"
        v-if="
          metaMaskStore.openMetaMaskPopup && !metaMaskStore.metaMaskUserWallet
        "
      >
        <div class="logo">
          <base-icon name="base/meta_mask" size="32px" filled />
        </div>
        <div class="content">
          <p class="content-title font--b2--b">Connect MetaMask</p>
          <p class="content-text font--b3">
            Connect wallet for exchange your bonus points to tokens
          </p>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="meta-mask-popup meta-mask-popup-account"
        v-if="
          metaMaskStore.openMetaMaskPopup && metaMaskStore.metaMaskUserWallet
        "
      >
        <div class="account">
          <div class="logo">
            <base-icon name="base/meta_mask" size="32px" filled />
          </div>
          <p class="font--b2--b">
            {{ truncateMiddle(metaMaskStore.metaMaskUserWallet, 5) }}
          </p>
        </div>
        <button
          type="button"
          class="account-button font--b2"
          @click.stop="disconnectMetaMask"
        >
          <base-icon name="base/turn_off_v2" size="24px" filled />
          Disconnect the wallet
        </button>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.overlay {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.meta-mask-popup {
  position: fixed;
  z-index: 12;
  top: 68px;
  right: calc((100% - 1390px) / 2);
  transform: translateX(-50%);
  width: 352px;
  border-radius: 16px;
  border: 1px solid var(--primary-orange);
  background: var(--white);
  backdrop-filter: blur(10px);
  padding: 15px 24px;
  box-shadow: var(--card-shadow);
}

.meta-mask-popup:hover {
  border: 2px solid var(--primary-orange);
  padding: 14px 23px;
}

.meta-mask-popup-connect {
  cursor: pointer;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  gap: 16px;
}

.meta-mask-popup-account {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
  gap: 12px;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}

.content-text {
  color: var(--grey-800);
}

.account {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.account-amount {
  font-weight: 700;
}

.account-button {
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border-radius: 40px;
  border: 1px solid var(--grey-800);
  color: var(--grey-800);
  padding: 10px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.account-button :deep(.base-icon) svg path {
  stroke: var(--grey-800);
  transition: all 0.3s ease;
}

.account-button:hover {
  border: 1px solid var(--primary-orange);
  color: var(--primary-orange);
}

.account-button:hover :deep(.base-icon) svg path {
  transition: all 0.3s ease;
  stroke: var(--primary-orange);
}

@media (max-width: 1350px) {
  .meta-mask-popup {
    right: -20px;
  }
}
</style>
