<script setup lang="ts">
import useMetaMask from '~/composables/meta-mask';
import { useMetaMaskStore } from '~/stores/meta-mask.store';
import { truncateMiddle } from '~/utilities/helpers/truncate-middle';

const metaMaskStore = useMetaMaskStore();

const { getUserWallet, disconnectMetaMask } = useMetaMask();

const state = defineModel<boolean>({ default: false });
</script>

<template>
  <div class="meta-mask-drawer-wrapper">
    <div
      v-if="!metaMaskStore.metaMaskUserWallet"
      class="meta-mask-drawer meta-mask-drawer-connect"
      @click="getUserWallet"
    >
      <div class="logo">
        <base-icon name="base/meta_mask" size="32px" filled />
      </div>
      <div class="content">
        <p class="font--b2--b">Connect MetaMask</p>
        <p class="content-text font--b3">
          Connect wallet for exchange your bonus points to tokens
        </p>
      </div>
    </div>
    <div v-else class="meta-mask-drawer meta-mask-drawer-account">
      <div class="account">
        <div class="logo">
          <base-icon name="base/meta_mask" size="32px" filled />
        </div>
        <p class="font--b2--b">
          {{ truncateMiddle(metaMaskStore.metaMaskUserWallet, 5) }}
        </p>
      </div>
      <!-- <span class="account-amount font--b2--r text--wh">$65.08</span> -->
      <button
        type="button"
        class="account-button font--b2"
        @click.stop="disconnectMetaMask"
      >
        <base-icon name="base/turn_off_v2" size="24px" filled />
        Disconnect the wallet
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.meta-mask-drawer {
  width: 100%;
  max-width: 460px;
  border-radius: 16px;
  padding: 16px 24px;
  background: transparent;
}

.meta-mask-drawer-connect {
  cursor: pointer;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  gap: 16px;
  border: 1px solid var(--main-orange);
}

.meta-mask-drawer-account {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
  gap: 12px;
  border: 1px solid var(--main-orange);
}

.meta-mask-drawer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}

.content-text {
  color: var(--grey-800);
}
.account {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.account-amount {
  font-weight: 700;
}

.account-button {
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border-radius: 40px;
  padding: 10px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
  border: 1px solid var(--primary-orange);
  color: var(--primary-orange);
}
</style>
