<template>
  <div class="logo-wrapper hover-scale-light">
    <Icon size="32px" name="icons:logo-coin" class="icon" />
    <Icon size="27px" name="icons:logo-text" class="text" />
  </div>
</template>

<style scoped lang="scss">
.logo-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  gap: 12px;
}

@media (max-width: 1200px) {
  .logo-wrapper {
    gap: 13.5px;
  }

  :deep(.icon) {
    font-size: 36px !important;
  }

  :deep(.text) {
    font-size: 30px !important;
  }
}
</style>
