export const useDisabledScroll = () => {
  const lockScroll = () => {
    document.querySelector('main')?.classList.add('disabled-scroll');
    document.querySelector('body')?.classList.add('disabled-scroll');
    document.querySelector('html')?.classList.add('disabled-scroll');
  };

  const unlockScroll = () => {
    document.querySelector('main')?.classList.remove('disabled-scroll');
    document.querySelector('body')?.classList.remove('disabled-scroll');
    document.querySelector('html')?.classList.remove('disabled-scroll');
  };

  return { lockScroll, unlockScroll };
};
