<script setup lang="ts">
import { useSwipe } from '@vueuse/core';
import { useDisabledScroll } from '~/composables/use-disabled-scroll';

const TRANSITION_DURATION_MS = 200;
const DRAG_THRESHOLD_PX = -100;

interface Props {
  showHandle?: boolean;
}

interface Emits {
  (e: 'open'): void;
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  showHandle: true,
});

const state = defineModel<boolean>({ default: false });

const drawerHandle = ref<HTMLDivElement | null>(null);
const isClosing = ref(false);
const screenWidth = ref<number | null>(null);

const onClose = () => {
  state.value = false;
};

const { lockScroll, unlockScroll } = useDisabledScroll();

watch(state, (newValue) => {
  if (screenWidth.value === null) return;
  if (newValue && screenWidth.value < 1280) {
    lockScroll();
    emit('open');
  } else {
    unlockScroll();
    emit('close');
  }
});

const { isSwiping, direction, lengthY } = useSwipe(drawerHandle, {
  threshold: 5,
  onSwipeEnd: () => {
    if (direction.value === 'down' && lengthY.value < DRAG_THRESHOLD_PX) {
      isClosing.value = true;
      setTimeout(() => {
        onClose();
        isClosing.value = false;
      }, TRANSITION_DURATION_MS);
    }
  },
});

const drawerTransform = computed(() => {
  if (isSwiping.value && direction.value === 'down') {
    return `translateY(${-lengthY.value}px)`;
  }

  if (isClosing.value) return 'translateY(100%)';
});

onMounted(() => {
  screenWidth.value = window.innerWidth;
  window.addEventListener('resize', () => {
    screenWidth.value = window.innerWidth;
  });
});
</script>

<template>
  <div class="drawer-wrapper">
    <Transition name="fade">
      <div v-if="state" class="overlay" @click="onClose" />
    </Transition>

    <Transition name="slide">
      <div
        v-if="state"
        ref="drawer"
        class="drawer"
        :class="{
          swiping: isSwiping,
        }"
        :style="{
          transform: drawerTransform!,
        }"
      >
        <div v-if="props.showHandle" ref="drawerHandle" class="drawer-handle">
          <div class="handle" />
        </div>
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.drawer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(7, 1, 3, 0.32);
  backdrop-filter: blur(2px);
  z-index: 12;
}

.drawer {
  display: flex;
  flex-direction: column;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;

  /* border-top: 1px solid var(--wh-40);
  border-right: 1px solid var(--wh-40);
  border-left: 1px solid var(--wh-40); */
  border-radius: 24px 24px 0 0;
  background: var(--white);

  z-index: 12;
  transition: transform v-bind(TRANSITION_DURATION_MS + 'ms') ease-out;
}

.drawer > * {
  padding: 0 24px;
}
.drawer:last-child {
  padding-bottom: 40px;
}

.drawer.swiping {
  transition: none;
}

.drawer-handle {
  height: 40px;
  border-radius: 3px;
  touch-action: pan-y;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.handle {
  width: 38px;
  height: 5px;
  border-radius: 6px;
  background-color: var(--gray-drawer);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity v-bind(TRANSITION_DURATION_MS + 'ms') ease;
}

.fade-leave-active {
  pointer-events: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform v-bind(TRANSITION_DURATION_MS + 'ms') ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100%);
}
</style>
